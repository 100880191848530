import { MakeGenerics, ReactLocation, Route } from '@tanstack/react-location';

import { Home } from 'components/Home';
import { StrainPropTypeEnum } from 'models';
import { StoreDetail, StoresMap, StoresOverview } from 'pages/store';
import { StrainDetail, StrainOverview } from 'pages/strain';
import { StrainPropDetail } from 'pages/strainProp';
import { Profile, UsersOverview } from 'pages/user';

export const ROUTE_CONSTANTS = {
  HOME_PATH: '/',
  ROOT_PATH: '.',
  PROFILE_PATH: 'profile',
  STRAIN_PATH: 'strains',
  STRAIN_DETAIL: ':id',
  STRAIN_PROP_PATH: 'strain-props',
  STRAIN_PROP_DETAIL_PATH: ':strainProp',
  USER_PATH: 'users',
  STORE_PATH: 'stores',
  STORE_MAP_PATH: 'stores-map',
  STORE_DETAIL: ':id',
};
export type LocationGenerics = MakeGenerics<{
  Search: {
    page?: number;
    pageSize?: number;
    filters?: string[];
    query?: string;
    bound?: string[];
  };
  RouteMeta: {
    breadcrumb: (params?: LocationGenerics['Params']) => string | undefined;
  };
  Params: {
    id: string;
    strainProp: StrainPropTypeEnum;
  };
}>;

export const reactLocation = new ReactLocation<LocationGenerics>();
export const appRoutes: Route<LocationGenerics>[] = [
  {
    path: ROUTE_CONSTANTS.HOME_PATH,
    element: <Home />,
  },
  {
    path: ROUTE_CONSTANTS.PROFILE_PATH,
    element: <Profile />,
    meta: {
      breadcrumb: () => ROUTE_CONSTANTS.PROFILE_PATH,
    },
  },
  {
    path: ROUTE_CONSTANTS.STRAIN_PATH,
    meta: {
      breadcrumb: () => ROUTE_CONSTANTS.STRAIN_PATH,
    },
    children: [
      {
        path: '/',
        element: <StrainOverview />,
      },
      {
        path: ROUTE_CONSTANTS.STRAIN_DETAIL,
        element: <StrainDetail />,
        meta: {
          breadcrumb: (params?: LocationGenerics['Params']) => params?.id,
        },
      },
    ],
  },
  {
    path: ROUTE_CONSTANTS.STRAIN_PROP_PATH,
    meta: {
      breadcrumb: () => ROUTE_CONSTANTS.STRAIN_PROP_PATH,
    },
    children: [
      {
        path: ROUTE_CONSTANTS.STRAIN_PROP_DETAIL_PATH,
        element: <StrainPropDetail />,
        meta: {
          breadcrumb: (params?: LocationGenerics['Params']) => params?.strainProp,
        },
      },
    ],
  },
  {
    path: ROUTE_CONSTANTS.USER_PATH,
    element: <UsersOverview />,
    meta: {
      breadcrumb: () => ROUTE_CONSTANTS.USER_PATH,
    },
  },
  {
    path: ROUTE_CONSTANTS.STORE_PATH,
    meta: {
      breadcrumb: () => ROUTE_CONSTANTS.STORE_PATH,
    },
    children: [
      {
        path: '/',
        element: <StoresOverview />,
      },
      {
        path: ROUTE_CONSTANTS.STORE_DETAIL,
        element: <StoreDetail />,
        meta: {
          breadcrumb: (params?: LocationGenerics['Params']) => params?.id,
        },
      },
    ],
  },
  {
    path: ROUTE_CONSTANTS.STORE_MAP_PATH,
    element: <StoresMap />,
    meta: {
      breadcrumb: () => ROUTE_CONSTANTS.STORE_MAP_PATH,
    },
  },
];
