import { Layout } from 'antd';

const { Content, Footer } = Layout;

const contentStyle: React.CSSProperties = {
  backgroundColor: '#fff',
};

const footerStyle: React.CSSProperties = {
  textAlign: 'center',
  backgroundColor: '#fff',
};

type CreateModalLayoutProps = {
  content: React.ReactNode;
  footer: React.ReactNode;
};

export const CreateModalLayout = ({ content, footer }: CreateModalLayoutProps) => {
  return (
    <Layout style={{ height: '740px' }}>
      <Content style={contentStyle}>{content}</Content>
      <Footer style={footerStyle}>{footer}</Footer>
    </Layout>
  );
};
