import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from '@tanstack/react-location';
import { Button, Popconfirm, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { useCurrentUser } from 'api/users';
import { Store } from 'models';

import { EditableCell } from './EditableCell';

type StoreTableProp = {
  stores?: Store[];
  isLoading?: boolean;
  onDelete: (id: string) => void;
};

export const StoreTable = ({ stores: data, isLoading, onDelete }: StoreTableProp) => {
  const { data: currentUser } = useCurrentUser();

  const canEditStore = currentUser?.permissions?.includes('update:stores');
  const canDeleteStore = currentUser?.permissions?.includes('delete:stores');

  const columns: ColumnsType<Store> = [
    {
      title: 'Title',
      render: (_, record) => record.basicInfo.title,
    },
    {
      title: 'Address',
      render: (_, record) => record.address.title,
    },
    {
      title: 'Link',
      render: (_, record) => record.basicInfo.link,
    },
    {
      title: 'Telephone',
      render: (_, record) => record.basicInfo.telephone,
    },
    {
      align: 'center',
      key: 'action',
      width: 50,
      render: (_, record) =>
        canEditStore && (
          <Space size='middle'>
            <Link to={record.id} onClick={(e) => e.stopPropagation()}>
              <EditOutlined />
            </Link>
            {canDeleteStore && (
              <Popconfirm
                title='Delete store'
                description='Are you sure to delete this store?'
                onConfirm={() => onDelete(record.id)}
                okText='Yes'
                cancelText='No'
              >
                <Button type='link' danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
          </Space>
        ),
    },
  ];

  return (
    <Table<Store>
      components={{
        body: {
          cell: EditableCell,
        },
      }}
      rowKey='id'
      bordered
      dataSource={data}
      columns={columns}
      pagination={false}
      loading={isLoading}
      size='small'
      scroll={{ x: 800, y: 720 }}
    />
  );
};
