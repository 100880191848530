import { useMutation } from '@tanstack/react-query';

import { getKushbergBackendAPIAdmin } from 'proxy';

function deleteStore(id: string) {
  return getKushbergBackendAPIAdmin().adminStoreControllerRemove(id);
}

export function useDeleteStore() {
  return useMutation(deleteStore);
}
