import { useMutation } from '@tanstack/react-query';

import { Store } from 'models';
import { CreateUpdateStoreRequest, getKushbergBackendAPIAdmin } from 'proxy';

const toRequest = (store: Store): CreateUpdateStoreRequest => ({
  title: store.basicInfo.title,
  type: store.basicInfo.type ?? null,
  address: store.address,
  link: store.basicInfo.link,
  telephone: store.basicInfo.telephone,
});

function updateStore(store: Store) {
  return getKushbergBackendAPIAdmin().adminStoreControllerUpdate(store.id, toRequest(store));
}

export function useUpdateStore() {
  return useMutation(updateStore);
}
