import Axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';

import { environment as env } from '../environments';

import { customAuth } from './customAuth';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: env.api,
  timeout: 5000,
});

async function authRequestInterceptor(config: InternalAxiosRequestConfig) {
  const accessToken = await customAuth.getAccessTokenSilently();

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
    config.headers.Accept = 'application/json';
  }
  return config;
}

AXIOS_INSTANCE.interceptors.request.use(authRequestInterceptor);
AXIOS_INSTANCE.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const message = error.response?.data?.message || error.message;
    return Promise.reject(message);
  }
);

export const customAxios = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token }).then(({ data }) => data);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};
