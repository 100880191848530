import { useAuth0 } from '@auth0/auth0-react';
import { useNetwork } from 'ahooks';
import { Button, Layout, notification, Result, Spin, theme } from 'antd';
import { ReactNode, Fragment, useEffect } from 'react';

import { Breadcrumb } from './Breadcrumb';
import { Footer } from './Footer';
import { Header } from './Header';
import layoutStyles from './layout.module.scss';
import { Sider } from './Sider';

const { Content } = Layout;

type MainLayoutProps = {
  children: ReactNode;
};

export function MainLayout({ children }: MainLayoutProps) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  const networkStatus = useNetwork();

  useEffect(() => {
    if (networkStatus.online) {
      notification.success({ message: 'You are online', placement: 'bottomRight', key: 'online' });
    } else {
      notification.error({
        message: 'You are offline',
        description: 'Check your connection.',
        placement: 'bottomRight',
        key: 'offline',
      });
    }
  }, [networkStatus.online]);

  return (
    <Fragment>
      {isLoading ? (
        <div className={layoutStyles['screenCenter']}>
          <Spin />
        </div>
      ) : isAuthenticated ? (
        <Layout hasSider className={layoutStyles['root__layout--height']}>
          <Header />
          <Footer />
          <Sider />
          <Layout className='site-layout' style={{ marginLeft: 200 }}>
            <Layout style={{ padding: '0 24px 24px' }}>
              <Content style={{ margin: '16px 0 0', overflow: 'initial' }}>
                <div style={{ height: 32, margin: 16 }}></div>
                <Breadcrumb />
                <div
                  style={{
                    padding: '0 0 48px',
                    textAlign: 'center',
                    background: colorBgContainer,
                  }}
                >
                  {children}
                </div>
              </Content>
            </Layout>
          </Layout>
        </Layout>
      ) : (
        <Result
          status='403'
          title='Unauthorized'
          subTitle='Please login.'
          extra={
            <Button type='primary' onClick={() => loginWithRedirect()}>
              Login
            </Button>
          }
        />
      )}
    </Fragment>
  );
}
