import { useNavigate, useSearch } from '@tanstack/react-location';
import { Input } from 'antd';
import { Fragment } from 'react';

import { GetStrainsParams, useStrainFilters, useStrains } from 'api/strains';
import { Pagination } from 'components/pagination';
import { StrainTable } from 'components/table';
import { LocationGenerics } from 'routes';

const { Search } = Input;

export const StrainOverview = () => {
  const search: GetStrainsParams = useSearch<LocationGenerics>();

  const { data, isLoading } = useStrains(search);

  const { data: propFilter } = useStrainFilters();

  const navigate = useNavigate<LocationGenerics>();

  const onPageClick = (page: number, pageSize: number) => {
    navigate({
      search: (old) => ({
        ...old,
        page: page,
        pageSize: pageSize,
      }),
    });
  };

  const onSearch = (value: string) => {
    navigate({
      search: (old) => ({
        ...old,
        query: value,
        page: 1,
      }),
    });
  };

  const onFilterChange = (ids: string[]) => {
    navigate({
      search: (old) => ({
        ...old,
        page: 1,
        filters: ids,
      }),
    });
  };

  return (
    <Fragment>
      <Search
        defaultValue={search?.query}
        style={{ padding: 24, width: 500 }}
        placeholder='Search by title'
        allowClear
        size='large'
        onSearch={onSearch}
        enterButton
      />
      <StrainTable
        strains={data?.data}
        filter={propFilter}
        defaultFilterValues={search?.filters}
        onFilterChange={onFilterChange}
        isLoading={isLoading}
      />
      {data?.pagination && <Pagination onClick={onPageClick} data={data.pagination} />}
    </Fragment>
  );
};
