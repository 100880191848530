import { Form, Space, Button } from 'antd';

import { Address } from 'models';

import { StoreAdresssForm } from '../form/StoreAddressForm';

import { CreateModalLayout } from './CreateModalLayout';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

type StoreAddressProps = {
  data?: Address;
  onNext: (value: Address) => void;
  onPrevious: () => void;
};

export const StoreAddress = ({ data, onNext, onPrevious }: StoreAddressProps) => {
  const [form] = Form.useForm<Address>();
  return (
    <Space direction='vertical' style={{ width: '100%', height: '100%' }}>
      <Form {...layout} initialValues={data} form={form} autoComplete='off' onFinish={onNext}>
        <CreateModalLayout
          content={<StoreAdresssForm form={form} />}
          footer={
            <Space>
              <Button onClick={onPrevious}>Previous</Button>
              <Button type='primary' htmlType='submit'>
                Next
              </Button>
            </Space>
          }
        />
      </Form>
    </Space>
  );
};
