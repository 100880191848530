import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { getKushbergBackendAPIAdmin } from 'proxy';
import { toStore } from 'services';

function getStore(id: string) {
  return getKushbergBackendAPIAdmin().adminStoreControllerFindOne(id);
}

export function useStore(id: string) {
  return useQuery(queryKeys.stores.detail(id), async () => getStore(id), {
    select: (data) => toStore(data),
  });
}
