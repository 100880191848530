import { useMatch } from '@tanstack/react-location';
import { useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { Fragment } from 'react';

import { useDeleteStrainProp, useStrainPropTypes, useUpdateStrainProp } from 'api/strains';
import { StrainPropTable } from 'components/table';
import { queryKeys } from 'lib/react-query';
import { StrainProp } from 'models';
import { LocationGenerics } from 'routes';

export const StrainPropDetail = () => {
  const {
    params: { strainProp },
  } = useMatch<LocationGenerics>();

  const [messageApi, contextHolder] = message.useMessage();

  const queryClient = useQueryClient();

  const { data, isLoading } = useStrainPropTypes(strainProp);

  const updateStrainPropMutation = useUpdateStrainProp();
  const deleteStrainPropMutation = useDeleteStrainProp();

  const onDelete = (id: string) => {
    deleteStrainPropMutation.mutate(id, {
      onSuccess: () => {
        messageApi.open({
          type: 'success',
          content: 'Strain property deleted',
        });
        queryClient.invalidateQueries(queryKeys.strainProps.lists(strainProp));
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: `something's wrong`,
        });
      },
    });
  };

  const onSave = (value: StrainProp) => {
    updateStrainPropMutation.mutate(value, {
      onSuccess: () => {
        messageApi.open({
          type: 'success',
          content: 'Strain property updated',
        });
        queryClient.invalidateQueries(queryKeys.strainProps.lists(strainProp));
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: `something's wrong`,
        });
      },
    });
  };

  return (
    <Fragment>
      {contextHolder}
      {data && (
        <StrainPropTable data={data} isLoading={isLoading} onDelete={onDelete} onSave={onSave} />
      )}
    </Fragment>
  );
};
