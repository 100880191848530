import { useNavigate, useSearch } from '@tanstack/react-location';
import { Button, Col, Input, message, Row } from 'antd';
import { Fragment, useState } from 'react';

import { GetStoresParams, useCreateStore, useDeleteStore, useStores } from 'api/stores';
import { Pagination } from 'components/pagination';
import { CreateStoreModal } from 'components/store/modal';
import { StoreTable } from 'components/table';
import { queryClient, queryKeys } from 'lib/react-query';
import { Address, StoreBasicInfo } from 'models';
import { LocationGenerics } from 'routes';

const { Search } = Input;

export const StoresOverview = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const search: GetStoresParams = useSearch<LocationGenerics>();

  const { data, isLoading } = useStores(search);
  const deleteStoreMutation = useDeleteStore();
  const createStoreMutation = useCreateStore();

  const navigate = useNavigate<LocationGenerics>();

  const onPageClick = (page: number, pageSize: number) => {
    navigate({
      search: (old) => ({
        ...old,
        page: page,
        pageSize: pageSize,
      }),
    });
  };

  const onSearch = (value: string) => {
    navigate({
      search: (old) => ({
        ...old,
        query: value,
        page: 1,
      }),
    });
  };

  const onDelete = (id: string) => {
    deleteStoreMutation.mutate(id, {
      onSuccess: () => {
        messageApi.open({
          type: 'success',
          content: 'Store delete',
        });
        queryClient.invalidateQueries(queryKeys.stores.lists());
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: `something's wrong`,
        });
      },
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onCreate = (store: { basicInfo: StoreBasicInfo; address: Address }) => {
    createStoreMutation.mutate(store, {
      onSuccess: () => {
        messageApi.open({
          type: 'success',
          content: 'Store created',
        });
        queryClient.invalidateQueries(queryKeys.stores.lists());
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: `something's wrong`,
        });
      },
    });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Fragment>
      {contextHolder}
      <CreateStoreModal open={isModalOpen} onCancel={handleCancel} onCreate={onCreate} />
      <Row style={{ padding: '10px' }}>
        <Col span={8}></Col>
        <Col span={8}>
          <Search
            defaultValue={search?.query}
            style={{ width: '100%' }}
            placeholder='Search by title'
            allowClear
            size='large'
            onSearch={onSearch}
            enterButton
          />
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <Button type='primary' onClick={showModal}>
            New Store
          </Button>
        </Col>
      </Row>

      {data && <StoreTable stores={data.data} isLoading={isLoading} onDelete={onDelete} />}
      {data?.pagination && <Pagination onClick={onPageClick} data={data.pagination} />}
    </Fragment>
  );
};
