import { Form, Input, Select } from 'antd';
import { Fragment } from 'react';

import { StoreTypeEnum } from 'models';

type StoreBasicInfoFormProps = {
  formName?: string;
};

export const StoreBasicInfoForm = ({ formName }: StoreBasicInfoFormProps) => {
  const formItemName = (name: string) => (formName ? [formName, name] : name);

  return (
    <Fragment>
      <Form.Item name='id' hidden>
        <Input />
      </Form.Item>
      <Form.Item
        label='Title'
        name={formItemName('title')}
        rules={[{ required: true, message: 'Please input Title!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label='Type' name={formItemName('type')}>
        <Select allowClear options={[{ value: StoreTypeEnum.Medical, label: 'Medical' }]} />
      </Form.Item>
      <Form.Item label='Link' name={formItemName('link')}>
        <Input />
      </Form.Item>
      <Form.Item label='Telephone' name={formItemName('telephone')}>
        <Input />
      </Form.Item>
    </Fragment>
  );
};
