import { useMatch } from '@tanstack/react-location';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Col, Divider, Form, message, Row, Space, Spin } from 'antd';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Marker, useMap } from 'react-leaflet';

import { useStore, useUpdateStore } from 'api/stores';
import { Map } from 'components/map';
import { StoreAdresssForm, StoreBasicInfoForm } from 'components/store/form';
import { queryKeys } from 'lib/react-query';
import { Store } from 'models';
import { LocationGenerics } from 'routes';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const tailLayout = {
  wrapperCol: { offset: 18, span: 20 },
};

export const StoreDetail = () => {
  const {
    params: { id: storeId },
  } = useMatch<LocationGenerics>();

  const queryClient = useQueryClient();

  const { data: store } = useStore(storeId);
  const updateStoreMutation = useUpdateStore();

  const [form] = Form.useForm<Store>();

  const [messageApi, contextHolder] = message.useMessage();

  const [isMapShow, setIsMapShow] = useState(false);

  const address = store?.address;

  const displayMap = useMemo(
    () => (
      <Map>
        <DisplayMarker
          center={
            address?.latitude && address?.longitude
              ? [address?.latitude, address?.longitude]
              : undefined
          }
          zoom={16}
        />
      </Map>
    ),
    [address?.latitude, address?.longitude]
  );

  const renderButtons = () => (
    <Form.Item {...tailLayout} style={{ padding: '12px' }}>
      <Space>
        <Button
          type='primary'
          onClick={() => setIsMapShow(!isMapShow)}
          disabled={address?.latitude === null || address?.longitude === null}
        >
          {isMapShow ? 'Hide map' : 'Show map'}
        </Button>
        <Button htmlType='button' onClick={onReset}>
          Reset
        </Button>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Space>
    </Form.Item>
  );

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = (value: Store) => {
    updateStoreMutation.mutate(value, {
      onSuccess: () => {
        messageApi.open({
          type: 'success',
          content: 'Store updated',
        });
        queryClient.invalidateQueries(queryKeys.stores.detail(storeId));
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: `something's wrong`,
        });
      },
    });
  };

  return store ? (
    <Fragment>
      {isMapShow && displayMap}
      {contextHolder}
      <Row>
        <Col span={20} offset={2}>
          <Form
            {...layout}
            form={form}
            initialValues={store}
            autoComplete='off'
            onFinish={onFinish}
          >
            {renderButtons()}
            <Divider orientation='left'>Basic Info</Divider>
            <StoreBasicInfoForm formName='basicInfo' />
            <Divider orientation='left'>Address</Divider>
            <StoreAdresssForm formName='address' form={form} />
            {renderButtons()}
          </Form>
        </Col>
      </Row>
    </Fragment>
  ) : (
    <Spin />
  );
};

function DisplayMarker({ center, zoom }: any) {
  const map = useMap();

  useEffect(() => {
    map.flyTo(center, zoom);
  }, [center, map, zoom]);

  return <Marker position={center} />;
}
