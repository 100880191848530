import { SearchOutlined } from '@ant-design/icons';
import { useSearch, useNavigate } from '@tanstack/react-location';
import { Button, Card, Input, Layout, List, theme } from 'antd';
import L, { LatLng, LatLngBounds } from 'leaflet';
import { Fragment, useEffect, useState } from 'react';
import { Marker, Popup, useMap, useMapEvents } from 'react-leaflet';

import { GetStoresParams, useStores } from 'api/stores';
import { Map } from 'components/map';
import { Store } from 'models';
import { LocationGenerics } from 'routes';

const { Search } = Input;

const { Content, Sider, Header } = Layout;

export const StoresMap = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const search: GetStoresParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();

  const [currentBound, setCurrentBound] = useState<LatLngBounds | undefined>(undefined);

  const [currentStore, setCurrentStore] = useState<Store | undefined>(undefined);

  const { data } = useStores(search);

  const isInsideBounds = (inner?: LatLngBounds, outer?: number[]): boolean => {
    if (inner === undefined) {
      return true;
    }
    if (outer === undefined) {
      return false;
    }
    // Check if the southwest corner of the inner bounds is inside the outer bounds
    if (
      inner.getSouthWest().lat >= outer[2] &&
      inner.getSouthWest().lat <= outer[0] &&
      inner.getSouthWest().lng >= outer[3] &&
      inner.getSouthWest().lng <= outer[1]
    ) {
      // Check if the northeast corner of the inner bounds is inside the outer bounds
      if (
        inner.getNorthEast().lat >= outer[2] &&
        inner.getNorthEast().lat <= outer[0] &&
        inner.getNorthEast().lng >= outer[3] &&
        inner.getNorthEast().lng <= outer[1]
      ) {
        return true;
      }
    }
    return false;
  };

  const showSearchButton = !isInsideBounds(
    currentBound,
    search?.bound?.map((n) => +n)
  );

  const onCurrentBoundSearch = () => {
    setCurrentStore(undefined);
    navigate({
      search: (old) => ({
        ...old,
        page: 1,
        pageSize: 100,
        ...(currentBound && {
          bound: [
            `${currentBound.getNorthEast().lat}`,
            `${currentBound.getNorthEast().lng}`,
            `${currentBound.getSouthWest().lat}`,
            `${currentBound.getSouthWest().lng}`,
          ],
        }),
      }),
    });
  };

  const onSearch = (value: string) => {
    setCurrentStore(undefined);
    setCurrentBound(undefined);
    navigate({
      search: (old) => ({
        ...old,
        query: value.length === 0 ? undefined : value,
        page: 1,
        pageSize: 100,
      }),
    });
  };

  return (
    <Layout>
      <Header
        style={{
          background: colorBgContainer,
        }}
      >
        <Search
          defaultValue={search?.query}
          style={{ width: 500, verticalAlign: 'middle' }}
          placeholder='Search by title'
          allowClear
          size='large'
          onSearch={onSearch}
          enterButton
        />
      </Header>
      <Layout>
        <Sider
          style={{
            background: colorBgContainer,
            overflow: 'auto',
            height: '65vh',
          }}
        >
          <List
            itemLayout='horizontal'
            dataSource={data?.data}
            renderItem={(item) => (
              <Card
                hoverable
                onClick={() => setCurrentStore(item)}
                bodyStyle={{
                  backgroundColor: item.id === currentStore?.id ? 'red' : colorBgContainer,
                }}
              >
                <Card.Meta
                  title={item.basicInfo.title}
                  description={`${item.basicInfo.link ?? ''} ${item.basicInfo.telephone ?? ''}`}
                />
              </Card>
            )}
          />
        </Sider>

        <Content>
          <Map minHeight='65vh'>
            {showSearchButton && (
              <Button
                shape='round'
                icon={<SearchOutlined />}
                onClick={onCurrentBoundSearch}
                style={{
                  display: 'absolute',
                  top: '20px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '400',
                }}
              >
                Search this area
              </Button>
            )}
            {data?.data && (
              <DisplayMarker
                pois={data?.data
                  .filter(
                    (store) =>
                      store.address.latitude &&
                      store.address.longitude &&
                      store.id !== currentStore?.id
                  )
                  .map((store) => ({
                    title: store.basicInfo.title,
                    latitude: store.address.latitude ?? 0,
                    longitude: store.address.longitude ?? 0,
                  }))}
                highlighPoi={
                  currentStore && {
                    title: currentStore.basicInfo.title,
                    latitude: currentStore.address.latitude ?? 0,
                    longitude: currentStore.address.longitude ?? 0,
                  }
                }
                setCurrentBound={setCurrentBound}
                fitBounds={currentBound === undefined}
              />
            )}
          </Map>
        </Content>
      </Layout>
    </Layout>
  );
};

const greenIcon = new L.Icon({
  iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
});

function DisplayMarker({
  pois,
  highlighPoi,
  setCurrentBound,
  fitBounds,
}: {
  pois: { title: string; latitude: number; longitude: number }[];
  highlighPoi?: { title: string; latitude: number; longitude: number };
  setCurrentBound: (bound: LatLngBounds) => void;
  fitBounds: boolean;
}) {
  const map = useMap();

  const bounds = (pois: { latitude: number; longitude: number }[]): LatLngBounds => {
    const latitudes = pois.map((p) => p?.latitude);
    const longitudes = pois.map((p) => p?.longitude);
    const minLatitude = Math.min(...latitudes);
    const maxLatitude = Math.max(...latitudes);
    const minLongitude = Math.min(...longitudes);
    const maxLongitude = Math.max(...longitudes);
    return new LatLngBounds(
      new LatLng(minLatitude, minLongitude),
      new LatLng(maxLatitude, maxLongitude)
    );
  };

  useEffect(() => {
    if (fitBounds && pois.length > 0) {
      map.fitBounds(bounds(pois));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fitBounds]);

  useEffect(() => {
    if (highlighPoi) {
      map.flyTo([highlighPoi.latitude, highlighPoi.longitude]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlighPoi?.latitude, highlighPoi?.longitude]);

  useMapEvents({
    moveend: () => {
      setCurrentBound(map.getBounds());
    },
  });

  return (
    <Fragment>
      {pois.map(
        (poi, index) =>
          poi && (
            <Marker key={index} position={[poi.latitude, poi.longitude]}>
              <Popup>{poi.title}</Popup>
            </Marker>
          )
      )}
      {highlighPoi && (
        <Marker position={[highlighPoi.latitude, highlighPoi.longitude]} icon={greenIcon}>
          <Popup autoPan>{highlighPoi.title}</Popup>
        </Marker>
      )}
    </Fragment>
  );
}
