import { Form, Input, InputNumber, Select } from 'antd';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  inputType: 'number' | 'text' | 'boolean';
  record: any;
  index: number;
  children: React.ReactNode;
}

const { Option } = Select;

export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  inputType,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === 'boolean' ? (
      <Select>
        <Option value={true}>true</Option>
        <Option value={false}>false</Option>
      </Select>
    ) : inputType === 'number' ? (
      <InputNumber />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
