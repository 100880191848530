import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { getKushbergBackendAPIAdmin } from 'proxy';
import { toUser } from 'services';

function getUsers() {
  return getKushbergBackendAPIAdmin().adminUserControllerGetUsers();
}

export function useUsers() {
  return useQuery(queryKeys.users.lists(), async () => getUsers(), {
    select: (data) => data.map(toUser),
  });
}
