import { Form, Space, Button } from 'antd';

import { StoreBasicInfo as StoreBasicInfoModel } from 'models';

import { StoreBasicInfoForm } from '../form';

import { CreateModalLayout } from './CreateModalLayout';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

type StoreBasicInfoTypes = {
  data?: StoreBasicInfoModel;
  onNext: (value: any) => void;
};

export const StoreBasicInfo = ({ data, onNext }: StoreBasicInfoTypes) => {
  const [form] = Form.useForm<StoreBasicInfoModel>();
  return (
    <Space direction='vertical' style={{ width: '100%', height: '100%' }}>
      <Form {...layout} initialValues={data} form={form} autoComplete='off' onFinish={onNext}>
        <CreateModalLayout
          content={<StoreBasicInfoForm />}
          footer={
            <Button type='primary' htmlType='submit'>
              Next
            </Button>
          }
        />
      </Form>
    </Space>
  );
};
