import { HomeOutlined } from '@ant-design/icons';
import { useMatches, Link } from '@tanstack/react-location';
import { Breadcrumb as AntBreadcrumb } from 'antd';

import { LocationGenerics, ROUTE_CONSTANTS } from 'routes';

export function Breadcrumb() {
  const matches = useMatches<LocationGenerics>();

  return (
    <AntBreadcrumb style={{ margin: '16px 0' }}>
      <AntBreadcrumb.Item key={'home'}>
        <Link to={ROUTE_CONSTANTS.HOME_PATH}>
          <HomeOutlined />
        </Link>
      </AntBreadcrumb.Item>
      {matches
        .filter((match) => match.route?.meta?.breadcrumb)
        .map((match, index) => (
          <AntBreadcrumb.Item key={index}>
            <Link to={match.pathname}>{match.route.meta?.breadcrumb(match.params)}</Link>
          </AntBreadcrumb.Item>
        ))}
    </AntBreadcrumb>
  );
}
