import { Link, useLocation } from '@tanstack/react-location';
import { Layout, Menu } from 'antd';

import { useCurrentUser } from 'api/users';
import { StrainPropTypeEnum } from 'models';
import { ROUTE_CONSTANTS } from 'routes';

const { Sider: AntSider } = Layout;

export function Sider() {
  const location = useLocation();
  const { data: currentUser } = useCurrentUser();
  const canReadStrain = currentUser?.permissions?.includes('read:strains');
  const canReadStore = currentUser?.permissions?.includes('read:stores');
  const canReadUser = currentUser?.permissions?.includes('read:users');

  const menuItems = [
    ...(canReadUser
      ? [
          {
            key: 'UserManagement',
            label: <Link to={ROUTE_CONSTANTS.USER_PATH}>Users</Link>,
          },
        ]
      : []),
    ...(canReadStore
      ? [
          {
            key: 'StoreManagement',
            label: 'Stores',
            children: [
              {
                key: ROUTE_CONSTANTS.STORE_PATH,
                label: <Link to={ROUTE_CONSTANTS.STORE_PATH}>All</Link>,
              },
              {
                key: ROUTE_CONSTANTS.STORE_MAP_PATH,
                label: <Link to={ROUTE_CONSTANTS.STORE_MAP_PATH}>Map</Link>,
              },
            ],
          },
        ]
      : []),
    ...(canReadStrain
      ? [
          {
            key: 'StrainManagement',
            label: 'Strains',
            children: [
              {
                key: ROUTE_CONSTANTS.STRAIN_PATH,
                label: <Link to={ROUTE_CONSTANTS.STRAIN_PATH}>All</Link>,
              },
              {
                key: ROUTE_CONSTANTS.STRAIN_PROP_PATH,
                type: 'group',
                label: 'Strain Properties',
                children: Object.keys(StrainPropTypeEnum).map((prop) => ({
                  key: `${ROUTE_CONSTANTS.STRAIN_PROP_PATH}/${prop}`,
                  label: <Link to={`${ROUTE_CONSTANTS.STRAIN_PROP_PATH}/${prop}`}>{prop}</Link>,
                })),
              },
            ],
          },
        ]
      : []),
  ];

  return (
    <AntSider
      style={{
        overflow: 'auto',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <div style={{ height: 32, margin: 16 }}></div>
      <Menu
        mode='inline'
        selectedKeys={[location.current.pathname.slice(1)]}
        defaultOpenKeys={['StoreManagement', 'StrainManagement', 'UserManagement']}
        style={{ height: '100%', borderRight: 0 }}
        items={menuItems}
      />
    </AntSider>
  );
}
