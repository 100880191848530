import { Auth0Provider } from '@auth0/auth0-react';
import { ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { environment as env } from 'environments';
import { COLORS } from 'styles/colors';

import App from './App';

import 'styles/index.scss';

ConfigProvider.config({
  theme: {
    primaryColor: COLORS.primary700,
    successColor: COLORS.success,
    infoColor: COLORS.info,
    warningColor: COLORS.warning,
    errorColor: COLORS.error,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={env.auth0Domain}
      clientId={env.auth0ClientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: env.auth0Audience,
        useRefreshTokens: true,
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);
