import { LatLngExpression } from 'leaflet';
import { MapContainer, TileLayer } from 'react-leaflet';

type MapProps = {
  center?: LatLngExpression;
  zoom?: number;
  minHeight?: string;
  children?: React.ReactNode;
};

export const Map: React.FC<MapProps> = ({ center, zoom, minHeight, children }) => {
  return (
    <MapContainer
      style={{ minHeight: minHeight ?? '30vh' }}
      center={center ?? [52.512458388442965, 13.40486526489258]}
      zoom={zoom ?? 12}
      scrollWheelZoom={false}
    >
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {children}
    </MapContainer>
  );
};
