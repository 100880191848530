import { Address, Store, StoreBasicInfo, StoreTypeEnum } from 'models';
import { AddressDto, StoreDto } from 'proxy';

export const toStore = (data: StoreDto): Store => ({
  id: data.id,
  basicInfo: toStoreBasicInfo(data),
  address: toAddress(data.address),
});

const toAddress = (data: AddressDto): Address => ({
  title: data.title,
  countryCode: data.countryCode,
  countryName: data.countryName,
  stateCode: data.stateCode,
  state: data.state,
  city: data.city,
  district: data.district,
  street: data.street,
  postalCode: data.postalCode,
  houseNumber: data.houseNumber,
  latitude: data.latitude,
  longitude: data.longitude,
});

const toStoreBasicInfo = (data: StoreDto): StoreBasicInfo => ({
  title: data.title,
  link: data.link,
  telephone: data.telephone,
  type: data.type && StoreTypeEnum[data.type],
});
