import { EditOutlined } from '@ant-design/icons';
import { Link } from '@tanstack/react-location';
import { Space, Tag, Typography, Table } from 'antd';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import { FilterValue } from 'antd/es/table/interface';
import { Fragment } from 'react';

import { useCurrentUser } from 'api/users';
import { Filter, Strain, StrainProp, StrainPropTypeEnum } from 'models';

import { ExpandedStrainRow } from './ExpandedStrainRow';

const { Text } = Typography;

type DataIndex = keyof Strain;

type StrainTableProp = {
  strains?: Strain[];
  filter?: Filter;
  defaultFilterValues?: string[];
  isLoading?: boolean;
  onFilterChange: (ids: string[]) => void;
};

type ColumnFilter = { text: string; value: string };

export const StrainTable = ({
  strains: data,
  isLoading,
  filter,
  defaultFilterValues,
  onFilterChange,
}: StrainTableProp) => {
  const { data: currentUser } = useCurrentUser();

  const canEditStrain = currentUser?.permissions?.includes('update:strains');

  const getPropColumn = (dataIndex: DataIndex): ColumnType<Strain> => {
    const filters: ColumnFilter[] = filter?.[dataIndex]?.map((v: StrainProp) => ({
      text: v.name,
      value: v.id,
    }));
    return {
      title: dataIndex,
      key: dataIndex,
      dataIndex: dataIndex,
      responsive: ['md'],
      filters: filters,
      filteredValue: filters
        ?.filter((f) => defaultFilterValues?.includes(f.value))
        .map((f) => f.value),
      render: (_, record: Strain) => (
        <Fragment>
          {(record?.[dataIndex] as StrainProp[])?.map(({ name }) => (
            <Tag key={name}>{name}</Tag>
          ))}
        </Fragment>
      ),
    };
  };

  const columns: ColumnsType<Strain> = [
    {
      title: 'Title',
      dataIndex: ['title', 'aka'],
      key: 'title',
      ellipsis: true,
      width: 200,
      render: (_, { title, aka }) => (
        <Space direction='vertical'>
          <Text strong>{title}</Text>
          {aka && (
            <Space>
              <Text ellipsis italic>
                aka
              </Text>
              <Text ellipsis>{aka}</Text>
            </Space>
          )}
        </Space>
      ),
    },
    getPropColumn(StrainPropTypeEnum.Category),
    getPropColumn(StrainPropTypeEnum.Ingredient),
    getPropColumn(StrainPropTypeEnum.Terpene),
    getPropColumn(StrainPropTypeEnum.Feeling),
    getPropColumn(StrainPropTypeEnum.Negative),
    getPropColumn(StrainPropTypeEnum.Flavor),
    getPropColumn(StrainPropTypeEnum.HelpsWith),
    {
      align: 'center',
      key: 'action',
      width: 50,
      render: (_, record) =>
        canEditStrain && (
          <Space size='middle'>
            <Link to={record.id} onClick={(e) => e.stopPropagation()}>
              <EditOutlined />
            </Link>
          </Space>
        ),
    },
  ];

  const onChange = (filters: Record<string, FilterValue | null>) =>
    onFilterChange(
      Object.keys(filters)
        .flatMap((key) => filters[key] ?? [])
        .map((v) => v.toString())
    );

  return (
    <Table<Strain>
      columns={columns}
      expandable={{
        expandedRowRender: (record) => <ExpandedStrainRow strain={record} />,
        expandRowByClick: true,
      }}
      rowKey='id'
      bordered
      dataSource={data}
      pagination={false}
      loading={isLoading}
      size='small'
      onChange={(_, filters) => onChange(filters)}
      scroll={{ x: 800, y: 720 }}
    />
  );
};
