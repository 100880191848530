import { Descriptions, Space } from 'antd';

import { Address, StoreBasicInfo } from 'models';

type StorePreviewFormProps = {
  basicInfo: StoreBasicInfo;
  address: Address;
};

export const StorePreviewForm = ({ basicInfo: baiscInfo, address }: StorePreviewFormProps) => {
  return (
    <Space direction='vertical'>
      <Descriptions title='Basic Info' column={1}>
        <Descriptions.Item label='Title'>{baiscInfo.title}</Descriptions.Item>
        <Descriptions.Item label='Link'>{baiscInfo.link}</Descriptions.Item>
        <Descriptions.Item label='Telephone'>{baiscInfo.telephone}</Descriptions.Item>
      </Descriptions>

      <Descriptions title='Address' column={1}>
        <Descriptions.Item label='Full text'>{address.title}</Descriptions.Item>
        <Descriptions.Item label='Latitude'>{address.longitude}</Descriptions.Item>
        <Descriptions.Item label='Longitude'>{address.latitude}</Descriptions.Item>
        <Descriptions.Item label='Country Code'>{address.countryCode}</Descriptions.Item>
        <Descriptions.Item label='Country Name'>{address.countryName}</Descriptions.Item>
        <Descriptions.Item label='State Code'>{address.stateCode}</Descriptions.Item>
        <Descriptions.Item label='State'>{address.state}</Descriptions.Item>
        <Descriptions.Item label='City'>{address.city}</Descriptions.Item>
        <Descriptions.Item label='District'>{address.district}</Descriptions.Item>
        <Descriptions.Item label='Street'>{address.street}</Descriptions.Item>
        <Descriptions.Item label='Postal Code'>{address.postalCode}</Descriptions.Item>
        <Descriptions.Item label='House Number'>{address.houseNumber}</Descriptions.Item>
      </Descriptions>
    </Space>
  );
};
