/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Kushberg Backend API (Admin)
 * The Kushberg backend API description
 * OpenAPI spec version: 1.0
 */
import { customAxios } from '../lib/axios';

import type {
  AddressDto,
  AdminStoreControllerFindAllParams,
  AdminStoreControllerVerifyAddressParams,
  AdminStrainControllerFindAllParams,
  AdminStrainPropTypeControllerFindAllParams,
  CreateUpdateStoreRequest,
  CreateUpdateStrainPropTypeRequest,
  CreateUpdateStrainRequest,
  CreateUpdateUserRequest,
  StoreDto,
  StorePageDto,
  StrainDto,
  StrainPageDto,
  StrainPropFilterDto,
  StrainPropTypeDto,
  UserDto,
} from './dtos';

export const getKushbergBackendAPIAdmin = () => {
  const adminStrainControllerCreate = (createUpdateStrainRequest: CreateUpdateStrainRequest) => {
    return customAxios<void>({
      url: `/api/v1/admin/strains`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateStrainRequest,
    });
  };

  const adminStrainControllerFindAll = (params?: AdminStrainControllerFindAllParams) => {
    return customAxios<StrainPageDto>({ url: `/api/v1/admin/strains`, method: 'GET', params });
  };

  const adminStrainControllerFindOne = (id: string) => {
    return customAxios<StrainDto>({ url: `/api/v1/admin/strains/${id}`, method: 'GET' });
  };

  const adminStrainControllerUpdate = (
    id: string,
    createUpdateStrainRequest: CreateUpdateStrainRequest
  ) => {
    return customAxios<void>({
      url: `/api/v1/admin/strains/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateStrainRequest,
    });
  };

  const adminStrainControllerRemove = (id: string) => {
    return customAxios<void>({ url: `/api/v1/admin/strains/${id}`, method: 'DELETE' });
  };

  const adminStrainControllerGetFilter = () => {
    return customAxios<StrainPropFilterDto[]>({
      url: `/api/v1/admin/strains/options/filter`,
      method: 'GET',
    });
  };

  const adminStrainPropTypeControllerCreate = (
    createUpdateStrainPropTypeRequest: CreateUpdateStrainPropTypeRequest
  ) => {
    return customAxios<void>({
      url: `/api/v1/admin/strain-prop-types`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateStrainPropTypeRequest,
    });
  };

  const adminStrainPropTypeControllerFindAll = (
    params: AdminStrainPropTypeControllerFindAllParams
  ) => {
    return customAxios<StrainPropTypeDto[]>({
      url: `/api/v1/admin/strain-prop-types`,
      method: 'GET',
      params,
    });
  };

  const adminStrainPropTypeControllerFindOne = (id: string) => {
    return customAxios<StrainPropTypeDto>({
      url: `/api/v1/admin/strain-prop-types/${id}`,
      method: 'GET',
    });
  };

  const adminStrainPropTypeControllerUpdate = (
    id: string,
    createUpdateStrainPropTypeRequest: CreateUpdateStrainPropTypeRequest
  ) => {
    return customAxios<void>({
      url: `/api/v1/admin/strain-prop-types/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateStrainPropTypeRequest,
    });
  };

  const adminStrainPropTypeControllerRemove = (id: string) => {
    return customAxios<void>({ url: `/api/v1/admin/strain-prop-types/${id}`, method: 'DELETE' });
  };

  const adminUserControllerGetCurrentUser = () => {
    return customAxios<UserDto>({ url: `/api/v1/admin/users/currentUser`, method: 'GET' });
  };

  const adminUserControllerUpdateCurrentUser = (
    createUpdateUserRequest: CreateUpdateUserRequest
  ) => {
    return customAxios<void>({
      url: `/api/v1/admin/users/currentUser`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateUserRequest,
    });
  };

  const adminUserControllerGetUsers = () => {
    return customAxios<UserDto[]>({ url: `/api/v1/admin/users`, method: 'GET' });
  };

  const adminUserControllerUpdateUser = (
    id: string,
    createUpdateUserRequest: CreateUpdateUserRequest
  ) => {
    return customAxios<void>({
      url: `/api/v1/admin/users/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateUserRequest,
    });
  };

  const adminStoreControllerCreate = (createUpdateStoreRequest: CreateUpdateStoreRequest) => {
    return customAxios<void>({
      url: `/api/v1/admin/stores`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateStoreRequest,
    });
  };

  const adminStoreControllerFindAll = (params?: AdminStoreControllerFindAllParams) => {
    return customAxios<StorePageDto>({ url: `/api/v1/admin/stores`, method: 'GET', params });
  };

  const adminStoreControllerFindOne = (id: string) => {
    return customAxios<StoreDto>({ url: `/api/v1/admin/stores/${id}`, method: 'GET' });
  };

  const adminStoreControllerUpdate = (
    id: string,
    createUpdateStoreRequest: CreateUpdateStoreRequest
  ) => {
    return customAxios<void>({
      url: `/api/v1/admin/stores/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateStoreRequest,
    });
  };

  const adminStoreControllerRemove = (id: string) => {
    return customAxios<void>({ url: `/api/v1/admin/stores/${id}`, method: 'DELETE' });
  };

  const adminStoreControllerVerifyAddress = (params: AdminStoreControllerVerifyAddressParams) => {
    return customAxios<AddressDto[]>({
      url: `/api/v1/admin/stores/verify-address`,
      method: 'POST',
      params,
    });
  };

  return {
    adminStrainControllerCreate,
    adminStrainControllerFindAll,
    adminStrainControllerFindOne,
    adminStrainControllerUpdate,
    adminStrainControllerRemove,
    adminStrainControllerGetFilter,
    adminStrainPropTypeControllerCreate,
    adminStrainPropTypeControllerFindAll,
    adminStrainPropTypeControllerFindOne,
    adminStrainPropTypeControllerUpdate,
    adminStrainPropTypeControllerRemove,
    adminUserControllerGetCurrentUser,
    adminUserControllerUpdateCurrentUser,
    adminUserControllerGetUsers,
    adminUserControllerUpdateUser,
    adminStoreControllerCreate,
    adminStoreControllerFindAll,
    adminStoreControllerFindOne,
    adminStoreControllerUpdate,
    adminStoreControllerRemove,
    adminStoreControllerVerifyAddress,
  };
};
export type AdminStrainControllerCreateResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getKushbergBackendAPIAdmin>['adminStrainControllerCreate']>>
>;
export type AdminStrainControllerFindAllResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getKushbergBackendAPIAdmin>['adminStrainControllerFindAll']>>
>;
export type AdminStrainControllerFindOneResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getKushbergBackendAPIAdmin>['adminStrainControllerFindOne']>>
>;
export type AdminStrainControllerUpdateResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getKushbergBackendAPIAdmin>['adminStrainControllerUpdate']>>
>;
export type AdminStrainControllerRemoveResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getKushbergBackendAPIAdmin>['adminStrainControllerRemove']>>
>;
export type AdminStrainControllerGetFilterResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getKushbergBackendAPIAdmin>['adminStrainControllerGetFilter']>
  >
>;
export type AdminStrainPropTypeControllerCreateResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getKushbergBackendAPIAdmin>['adminStrainPropTypeControllerCreate']>
  >
>;
export type AdminStrainPropTypeControllerFindAllResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getKushbergBackendAPIAdmin>['adminStrainPropTypeControllerFindAll']
    >
  >
>;
export type AdminStrainPropTypeControllerFindOneResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getKushbergBackendAPIAdmin>['adminStrainPropTypeControllerFindOne']
    >
  >
>;
export type AdminStrainPropTypeControllerUpdateResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getKushbergBackendAPIAdmin>['adminStrainPropTypeControllerUpdate']>
  >
>;
export type AdminStrainPropTypeControllerRemoveResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getKushbergBackendAPIAdmin>['adminStrainPropTypeControllerRemove']>
  >
>;
export type AdminUserControllerGetCurrentUserResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getKushbergBackendAPIAdmin>['adminUserControllerGetCurrentUser']>
  >
>;
export type AdminUserControllerUpdateCurrentUserResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getKushbergBackendAPIAdmin>['adminUserControllerUpdateCurrentUser']
    >
  >
>;
export type AdminUserControllerGetUsersResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getKushbergBackendAPIAdmin>['adminUserControllerGetUsers']>>
>;
export type AdminUserControllerUpdateUserResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getKushbergBackendAPIAdmin>['adminUserControllerUpdateUser']>
  >
>;
export type AdminStoreControllerCreateResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getKushbergBackendAPIAdmin>['adminStoreControllerCreate']>>
>;
export type AdminStoreControllerFindAllResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getKushbergBackendAPIAdmin>['adminStoreControllerFindAll']>>
>;
export type AdminStoreControllerFindOneResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getKushbergBackendAPIAdmin>['adminStoreControllerFindOne']>>
>;
export type AdminStoreControllerUpdateResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getKushbergBackendAPIAdmin>['adminStoreControllerUpdate']>>
>;
export type AdminStoreControllerRemoveResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getKushbergBackendAPIAdmin>['adminStoreControllerRemove']>>
>;
export type AdminStoreControllerVerifyAddressResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getKushbergBackendAPIAdmin>['adminStoreControllerVerifyAddress']>
  >
>;
