import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { getKushbergBackendAPIAdmin } from 'proxy';
import { toUser } from 'services';

function getCurrentUser() {
  return getKushbergBackendAPIAdmin().adminUserControllerGetCurrentUser();
}

export function useCurrentUser() {
  return useQuery(queryKeys.currentUser.all, async () => getCurrentUser(), {
    select: (data) => toUser(data),
  });
}
