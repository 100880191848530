import { Link } from '@tanstack/react-location';
import { Form, Space, Table, Typography } from 'antd';
import { useState } from 'react';

import { useCurrentUser } from 'api/users';
import { User } from 'models';

import { EditableCell } from './EditableCell';

type UserTableProp = {
  users?: User[];
  isLoading?: boolean;
  onSave: (value: User) => void;
};

const { Text } = Typography;

export const UserTable = ({ users: data, isLoading, onSave }: UserTableProp) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const { data: currentUser } = useCurrentUser();

  const canEditUser = currentUser?.permissions?.includes('update:users');

  const isEditing = (record: User) => record.id === editingKey;

  const edit = (record: User) => {
    form.setFieldsValue(record);
    setEditingKey(record.id);
  };

  const onCancel = () => {
    setEditingKey('');
  };

  const onItemSave = (prop: User) => {
    onSave(prop);
    setEditingKey('');
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      ellipsis: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      editable: true,
    },
    {
      title: 'Nickname',
      dataIndex: 'nickname',
      editable: true,
    },
    {
      title: 'Picture',
      dataIndex: 'picture',
      ellipsis: true,
      editable: true,
    },
    {
      title: 'Email Verified',
      dataIndex: 'emailVerified',
      render: (_: any, record: User) => <Text>{record.emailVerified ? 'true' : 'false'}</Text>,
    },
    {
      dataIndex: 'operation',
      width: '15%',
      render: (_: any, record: User) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Link onClick={() => onItemSave({ ...form.getFieldsValue(), id: record.id })}>
              Save
            </Link>
            <Link onClick={onCancel}>Cancel</Link>
          </Space>
        ) : (
          <Space>
            {canEditUser && (
              <Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                Edit
              </Link>
            )}
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: User) => ({
        record,
        dataIndex: col.dataIndex,
        inputType: col.dataIndex === 'emailVerified' ? 'boolean' : 'text',
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table<User>
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        rowKey='id'
        bordered
        dataSource={data}
        columns={mergedColumns}
        pagination={false}
        loading={isLoading}
        size='large'
      />
    </Form>
  );
};
