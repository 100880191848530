import { Filter, Strain, StrainProp, StrainPropTypeEnum } from 'models';
import { StrainDto, StrainPropDto, StrainPropFilterDto, StrainPropTypeDto } from 'proxy';

export const toStrain = (data: StrainDto): Strain => ({
  id: data.id,
  title: data.title,
  slug: data.slug,
  aka: data.aka,
  description: data.description,
  growingInfo: data.growingInfo,

  [StrainPropTypeEnum.Category]: data.category && [toStrainProp(data.category)],
  [StrainPropTypeEnum.Ingredient]: data.concentrations?.map(toStrainProp),
  [StrainPropTypeEnum.Terpene]: data.terpene && [toStrainProp(data.terpene)],

  [StrainPropTypeEnum.GrowingType]: data.growingType && toStrainProp(data.growingType),
  [StrainPropTypeEnum.GrowingDifficulty]:
    data.growingDifficulty && toStrainProp(data.growingDifficulty),
  [StrainPropTypeEnum.GrowingHeight]: data.growingHeight && toStrainProp(data.growingHeight),
  [StrainPropTypeEnum.GrowingYield]: data.growingYield && toStrainProp(data.growingYield),
  [StrainPropTypeEnum.GrowingFlowering]:
    data.growingFlowering && toStrainProp(data.growingFlowering),

  [StrainPropTypeEnum.Feeling]: data.feelings?.map(toStrainProp),
  [StrainPropTypeEnum.Negative]: data.negatives?.map(toStrainProp),
  [StrainPropTypeEnum.Flavor]: data.flavors?.map(toStrainProp),
  [StrainPropTypeEnum.HelpsWith]: data.helpsWiths?.map(toStrainProp),

  seedLinks: data.seedLinks?.map((seed) => ({
    id: seed.id,
    url: seed.url,
    price: seed.price ?? 0,
    storeName: seed.storeName,
    storeLogoUrl: seed.storeLogoUrl,
    productTitle: seed.productTitle,
  })),

  children: data.children?.map(toStrain),
  parent: data.parent && toStrain(data.parent),
});

export const toStrainProp = (data: StrainPropDto): StrainProp => ({
  id: data.id,
  value: data.value,
  slug: data.slug,
  name: data.name,
  icon: data.icon,
  type: StrainPropTypeEnum[data.type],
});

export const toStrainPropType = (data: StrainPropTypeDto): StrainProp => ({
  id: data.id,
  name: data.name,
  slug: data.slug,
  icon: data.icon,
  type: StrainPropTypeEnum[data.type],
  strainCount: data.strainCount,
});

export const toFilter = (data: StrainPropFilterDto[]): Filter => {
  return Object.assign(
    {},
    ...data.map((type) => ({ [StrainPropTypeEnum[type.type]]: type.values }))
  );
};
