import { Modal, Steps, Col, Row, Space } from 'antd';
import { useState } from 'react';

import { Address, StoreBasicInfo as StoreBasicInfoModel } from 'models';

import { StoreAddress } from './StoreAddress';
import { StoreBasicInfo } from './StoreBasicInfo';
import { StorePreview } from './StorePreview';

type CreateStoreModalProps = {
  open: boolean;
  onCreate: (store: { basicInfo: StoreBasicInfoModel; address: Address }) => void;
  onCancel?: () => void;
};

export const CreateStoreModal = ({ open, onCreate, onCancel }: CreateStoreModalProps) => {
  const [basicInfo, setBasicInfo] = useState<StoreBasicInfoModel>();
  const [address, setAddress] = useState<Address>();

  const [current, setCurrent] = useState(0);

  const prev = () => {
    setCurrent(current - 1);
  };
  const next = () => {
    setCurrent(current + 1);
  };

  const onStoreNext = (value: StoreBasicInfoModel) => {
    setBasicInfo(value);
    next();
  };

  const onAddressNext = (value: Address) => {
    setAddress(value);
    next();
  };

  const onReset = () => {
    setCurrent(0);
    setAddress(undefined);
    setBasicInfo(undefined);
  };

  const steps = [
    {
      title: 'Basic Info',
      content: <StoreBasicInfo data={basicInfo} onNext={onStoreNext} />,
    },
    {
      title: 'Address',
      content: <StoreAddress data={address} onNext={onAddressNext} onPrevious={prev} />,
    },

    {
      title: 'Preview',
      content: basicInfo && address && (
        <StorePreview
          basicInfo={basicInfo}
          address={address}
          onPrevious={prev}
          onSubmit={() => {
            onCreate({ basicInfo, address });
            onReset();
          }}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <Modal
      title='New Store'
      open={open}
      footer={null}
      onCancel={onCancel}
      width='800px'
      centered
      bodyStyle={{ height: '800px' }}
    >
      <Space direction='vertical' size='large' style={{ width: '100%' }}>
        <Steps current={current} items={items} />
        <Row>
          <Col span={20} offset={2}>
            {steps[current].content}
          </Col>
        </Row>
      </Space>
    </Modal>
  );
};
