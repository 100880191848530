import { Address } from './address.model';

export type Store = {
  id: string;
  basicInfo: StoreBasicInfo;
  address: Address;
};

export type StoreBasicInfo = {
  title: string;
  type?: StoreTypeEnum;
  link?: string;
  telephone?: string;
};

export enum StoreTypeEnum {
  Medical = 'Medical',
}
