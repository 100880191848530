/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { GetRef, InputRef, TableProps } from 'antd';
import { Button, Divider, Form, Input, Popconfirm, Table } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { SeedLink } from 'models';

type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof SeedLink;
  record: SeedLink;
  handleSave: (record: SeedLink) => void;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[{ required: true, message: `${title} is required.` }]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className='editable-cell-value-wrap'
        style={{ paddingInlineEnd: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type ColumnTypes = Exclude<TableProps<SeedLink>['columns'], undefined>;
type SeedLinksTableProps = {
  dataSource: SeedLink[];
  setDataSource: (seedLinks: SeedLink[]) => void;
};
const SeedLinksTable: React.FC<SeedLinksTableProps> = ({ dataSource, setDataSource }) => {
  const [count, setCount] = useState(2);

  const handleDelete = (url: string) => {
    const newData = dataSource.filter((item) => item.url !== url);
    setDataSource(newData);
  };

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    itemType?: 'number';
  })[] = [
    {
      title: 'Store Name',
      dataIndex: 'storeName',
      width: '30%',
      editable: true,
    },
    {
      title: 'URL',
      dataIndex: 'url',
      editable: true,
    },
    {
      title: 'LogoUrl',
      dataIndex: 'storeLogoUrl',
      editable: true,
    },
    {
      title: 'Title',
      dataIndex: 'productTitle',
      editable: true,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      width: '30%',
      editable: true,
      itemType: 'number',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record: SeedLink) =>
        dataSource.length >= 1 ? (
          <Popconfirm title='Sure to delete?' onConfirm={() => handleDelete(record.url)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData: SeedLink = {
      storeName: `Example ${count}`,
      url: `https://www.example-${count}.com`,
      price: 0,
      storeLogoUrl: `https://www.example-${count}.com/logo`,
      productTitle: `Product ${count}`,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: SeedLink) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: SeedLink) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        itemType: col.itemType || 'text',
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div>
      <Divider orientation='left'>Seed Links</Divider>
      <Button onClick={handleAdd} type='primary' style={{ marginBottom: 16 }}>
        Add a row
      </Button>
      <Table<SeedLink>
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        rowKey={(data) => data.url}
        dataSource={dataSource}
        columns={columns as ColumnTypes}
      />
    </div>
  );
};

export default SeedLinksTable;
