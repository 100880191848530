import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { getKushbergBackendAPIAdmin } from 'proxy';
import { toFilter } from 'services';

function getStrainFilters() {
  return getKushbergBackendAPIAdmin().adminStrainControllerGetFilter();
}

export function useStrainFilters() {
  return useQuery(queryKeys.strains.filters(), async () => getStrainFilters(), {
    select: (data) => toFilter(data),
  });
}
