import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { StrainPropTypeEnum } from 'models';
import { getKushbergBackendAPIAdmin } from 'proxy';
import { toStrainPropType } from 'services';

function getStrainPropTypes(type: StrainPropTypeEnum) {
  return getKushbergBackendAPIAdmin().adminStrainPropTypeControllerFindAll({ type });
}

export function useStrainPropTypes(type: StrainPropTypeEnum) {
  return useQuery(queryKeys.strainProps.lists(type), async () => getStrainPropTypes(type), {
    select: (data) => data.map(toStrainPropType),
  });
}
