import { useMutation } from '@tanstack/react-query';

import { StrainProp } from 'models';
import { CreateUpdateStrainPropTypeRequest, getKushbergBackendAPIAdmin } from 'proxy';

const toCreateUpdateStrainPropTypeDto = (data: StrainProp): CreateUpdateStrainPropTypeRequest => ({
  type: data.type,
  icon: data.icon,
  name: data.name,
  slug: data.slug,
});

function updateStrainProp(strainProp: StrainProp) {
  return getKushbergBackendAPIAdmin().adminStrainPropTypeControllerUpdate(
    strainProp.id,
    toCreateUpdateStrainPropTypeDto(strainProp)
  );
}

export function useUpdateStrainProp() {
  return useMutation(updateStrainProp);
}
