import { Pagination } from 'models';

export const toPagination = (data: {
  page: number;
  pageSize: number;
  totalData: number;
  totalPages: number;
}): Pagination => ({
  page: data.page,
  pageSize: data.pageSize,
  totalData: data.totalData,
  totalPages: data.totalPages,
});
