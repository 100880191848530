import {
  DefaultOptions,
  QueryClient,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { PromiseValue } from 'type-fest';

import { GetStoresParams } from 'api/stores';
import { GetStrainsParams } from 'api/strains';
import { StrainPropTypeEnum } from 'models';

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    staleTime: 10000,
    retry: false,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export type QueryConfig<FetcherFnType extends (...args: any) => any> = UseQueryOptions<
  PromiseValue<ReturnType<FetcherFnType>>
>;

export type MutationConfig<FetcherFnType extends (...args: any) => any> = UseMutationOptions<
  PromiseValue<ReturnType<FetcherFnType>>,
  AxiosError,
  Parameters<FetcherFnType>[0]
>;

// Query Key factories => https://tkdodo.eu/blog/effective-react-query-keys
export const queryKeys = {
  strainProps: {
    all: ['strainProps'] as const,
    lists: (type: StrainPropTypeEnum) => [...queryKeys.strainProps.all, 'list', type] as const,
  },
  strains: {
    all: ['strains'] as const,
    lists: () => [...queryKeys.strains.all, 'list'] as const,
    details: () => [...queryKeys.strains.all] as const,
    detail: (id: string) => [...queryKeys.strains.details(), id] as const,
    filters: () => [...queryKeys.strains.all, 'filters'] as const,
    listslWithParams: (params?: GetStrainsParams) =>
      [...queryKeys.strains.lists(), { params }] as const,
  },
  currentUser: {
    all: ['currentUser'] as const,
  },
  stores: {
    all: ['stores'] as const,
    lists: () => [...queryKeys.stores.all, 'list'] as const,
    details: () => [...queryKeys.stores.all] as const,
    detail: (id: string) => [...queryKeys.stores.details(), id] as const,
    listslWithParams: (params?: GetStoresParams) =>
      [...queryKeys.stores.lists(), { params }] as const,
  },
  users: {
    all: ['users'] as const,
    lists: () => [...queryKeys.users.all, 'list'] as const,
  },
};
