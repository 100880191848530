import { Result, Button } from 'antd';
import type { FallbackProps } from 'react-error-boundary';

import { ErrorMessage } from 'models';

export function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  console.log(error.toString());
  switch (error.toString()) {
    case ErrorMessage.E403P:
      return (
        <Result
          status='403'
          title='403'
          subTitle='Sorry, you are not authorized to access this page.'
          extra={
            <Button type='primary' onClick={resetErrorBoundary}>
              Back to home
            </Button>
          }
        />
      );
    default:
      return (
        <Result
          status='500'
          title='500'
          subTitle='Sorry, something went wrong.'
          extra={
            <Button type='primary' onClick={resetErrorBoundary}>
              Back to home
            </Button>
          }
        />
      );
  }
}

export default ErrorFallback;
