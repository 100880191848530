import { Router } from '@tanstack/react-location';
import { ReactLocationDevtools } from '@tanstack/react-location-devtools';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createContext, ReactNode, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { RecoilRoot } from 'recoil';

import { ErrorFallback } from 'components/error';
import { queryClient } from 'lib/react-query';
import { reactLocation, appRoutes } from 'routes';

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

type AppProviderProps = {
  children: ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  function onErrorReset() {
    window.location.href = '/';
  }

  return (
    <RecoilRoot>
      <Suspense>
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={onErrorReset}>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV === 'development' && (
              <ReactQueryDevtools position='bottom-left' initialIsOpen={false} />
            )}
            <Router location={reactLocation} routes={appRoutes}>
              {process.env.NODE_ENV === 'development' && (
                <ReactLocationDevtools position='bottom-right' />
              )}
              {children}
            </Router>
          </QueryClientProvider>
        </ErrorBoundary>
      </Suspense>
    </RecoilRoot>
  );
};
