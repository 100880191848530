import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { getKushbergBackendAPIAdmin } from 'proxy';
import { toStrain } from 'services';

function getStrain(id: string) {
  return getKushbergBackendAPIAdmin().adminStrainControllerFindOne(id);
}

export function useStrain(id: string) {
  return useQuery(queryKeys.strains.detail(id), async () => getStrain(id), {
    select: (data) => toStrain(data),
  });
}
